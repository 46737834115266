export const converters = {
    "XNO": {
      "logo": "https://nanswap.com/logo/XNO.svg",
      "name": "Nano",
      "uri": 'nano',
      "explorer": "https://nanexplorer.com/nano/account/",
      "iconName": "Natricon",
      "iconUrl": "https://i.nanswap.com/unsafe/plain/https%3A%2F%2Fnatricon.com%2Fapi%2Fv1%2Fnano%3Faddress%3D",
      "wallets": [
        {
            icon: 'nault.ico',
            url: "https://nault.cc/", name: "Nault", platform: "Web", isMobile: false, sendUrl: (address, amount) => `https://nault.cc/send?to=${address}`, recommended: true},
      ],
      "prefix": "nano",
      "rank": 0,
    },
    "BAN": {
      "logo": "https://nanswap.com/logo/BAN.svg",
      "name": "Banano",
      "explorer": "https://nanexplorer.com/banano/account/",
      "iconName": "MonKey",
      "iconUrl": "https://i.nanswap.com/u/plain/https%3A%2F%2Fmonkey.banano.cc%2Fapi%2Fv1%2Fmonkey%2F",
      "faucet": "/banano-faucet",
      "wallets": [
        // bananostand not yet support custom address without amount: https://github.com/dev-ptera/thebananostand/issues/144
        {url: "https://thebananostand.com/", name: "The Banano Stand", platform: "Web", icon: "thebananostand.png", recommended: true, sendUrl: (address, amount) => `https://thebananostand.com/`},
      ],
      "prefix": "ban",
      "uri": 'ban',
      "rank": 1,
      "representative": "ban_1banexkcfuieufzxksfrxqf6xy8e57ry1zdtq9yn7jntzhpwu4pg4hajojmq", // for nodes docs
      "decimal": 29,
      "decimalsToShow": 2,

    },
    "XDG": {
      "logo": "https://dogenano.io/static/media/XDG.023c3302.png",
      "name": "DogeNano",
      "explorer":  "https://nanexplorer.com/dogenano/account/",
      "iconName": "Doggycon",
      "iconUrl": "https://doggycon.dogenano.io/api/v1/pilou/",
      "faucet": "/dogenano-faucet",
      "wallets": [
        {url: "https://wallet.dogenano.io/", name: "Dault", platform: "Web", icon: "nault.ico", sendUrl: (address, amount) => `https://wallet.dogenano.io/send?to=${address}`, recommended: true},
      ],
      "prefix": "xdg",
      "uri": "nano",
      "rank": 3,
      "representative": "xdg_1e4ecrhmcws6kwiegw8dsbq5jstq7gqj7fspjmgiu11q55s6xnsnp3t9jqxf", // for nodes docs
      "decimal": 26,
      "decimalsToShow": 3,

    },
    "ANA": {
      "logo": "https://nanswap.com/logo/ANA.png",
      "name": "Ananos",
      "explorer":  "https://nanexplorer.com/ananos/account/",
      "iconName": "",
      "iconUrl": "",
      "faucet": "/ananos-faucet",
      "wallets": [
        {url: "https://ananault.lightcord.org/", name: "AnaNault", platform: "Web", icon: "nault.ico", sendUrl: (address, amount) => `https://ananault.lightcord.org/send?to=${address}`, recommended: true},
      ],
      "prefix": "ana",
      "uri": "nano",
      "rank": 4,
      "representative": "ana_1nanswapnscbjjr6nd8bjbyp7o3gby1r8m18rbmge3mj8y5bihh71sura9dx",
      "decimal": 28,  
      "decimalsToShow": 0,
    },
    "XRO": {
      "logo": "https://nanswap.com/logo/XRO.png",
      "name": "RaiblocksOne",
      "explorer":  "https://nanexplorer.com/raiblocksone/account/",
      "iconName": "",
      "iconUrl": "",
      "faucet": "/raiblocks1-faucet",
      "wallets": [
        {url: "https://vault.raione.cc/", name: "RaiOne Vault", platform: "Web", icon: "nault.ico", recommended: true, sendUrl: (address, amount) => `https://vault.raione.cc/send?to=${address}`},
      ],
      "prefix": "xro",
      "uri": "xro",
      "rank": 5,
      "representative": "xro_1nanswapnscbjjr6nd8bjbyp7o3gby1r8m18rbmge3mj8y5bihh71sura9dx",
      "decimal": 30,  
      "decimalsToShow": 5,
    },
  }