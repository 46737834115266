import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Typography, Space, Card, Collapse, Skeleton, Row, Col, Modal, QRCode, Flex, Avatar, Divider, Result } from 'antd';
import { DiscordOutlined, GlobalOutlined, InstagramOutlined, UserOutlined, XOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as webauthn from '@passwordless-id/webauthn';
import useSWR from 'swr'
import Paragraph from 'antd/es/typography/Paragraph';
import copy from 'copy-to-clipboard';
import { QRCodeSVG } from 'qrcode.react';
import { converters } from '../converters';
import { Helmet } from 'react-helmet';
const fetcher = (...args) => fetch(...args).then(res => res.json())

const ButtonAddress = ({ username, address, ticker }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button
        shape='round'
        style={{ display: 'block' }}
        size='large'
        onClick={() => setVisible(true)}>
        <div style={{ textAlign: "center" }}>
          <div style={{ position: "absolute" }}>
            <img src=
              {converters[ticker]['logo']}
              alt={'logo-' + ticker}
              width={"24px"} />
          </div>
          <div style={{ marginLeft: 12 }}>
            {address.slice(0, 10)}...{address.slice(-6)}
          </div>
        </div>
      </Button>
      <Modal
        // okButtonProps={{ type: 'default' }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        // title={<div style={{ display: "flex" }}><img src="https://nanswap.com/logo/XNO.svg" alt='logo-nano' style={{ width: '20px', marginRight: 8 }} /> Nano Address</div>}
        title={`@${username}@xno.link ${converters[ticker]['name']} Address`}
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        <Row justify={"center"} style={{ marginTop: 16 }}>
          <Card size='small'>
            <QRCodeSVG
              value={address}
              imageSettings={{
                src: converters[ticker]['logo'],
                width: 32,
                height: 32,
              }}
              size={150}
            /></Card>

        </Row>
        <Paragraph
          onClick={() => {
            let r = copy(address);
            if (r) {
              message.success("Address Copied");
            } else {
              message.error("Failed to copy");
            }
          }}
          style={{ maxWidth: 200, textAlign: "center", marginRight: "auto", marginLeft: "auto", marginTop: 16, cursor: "pointer" }}>
          {address}
        </Paragraph>
        <div style={{ textAlign: "center", marginTop: 16 }}>
          <Button
            onClick={() => {
              let r = copy(address);
              if (r) {
                message.success("Address Copied");
              }
              else {
                message.error("Failed to copy");
              }
            }}
            style={{ width: '100%', marginBottom: 8 }}
            size='large' shape='round'
            type='primary'
            color='success'
          >Copy {converters[ticker]['name']} Address</Button>
          <Button
            style={{ width: '100%' }}
            size='large' shape='round'
            type='default'
            href={`${converters[ticker]['uri']}:${address}`} target='_blank'>
            Open {converters[ticker]['name']} Wallet
          </Button>
          <OpenWebWallets ticker={ticker} address={address} />
          <Button
            size='large'
            style={{ width: '100%', marginTop: 8 }}
            type='link' href={`${converters[ticker]['explorer']}${address}`}
            target='_blank'>View on Nanexplorer</Button>
        </div>
      </Modal>
    </>
  );
}
const OpenWebWallets = ({ ticker, address }) => {
  return (<>
    {
      converters[ticker]['wallets'].map((wallet, i) => {
        return <Button
          icon={<img src={'./images/' + wallet.icon} alt={'logo-' + wallet.name}
            style={{ width: '20px' }} />}
          size='large'
          shape='round'
          type='default'
          style={{ width: '100%', marginTop: 8 }}
          href={wallet.sendUrl(address, 1)}
          target='_blank'
          key={i}>
          {wallet['name']}
        </Button>
      })
    }
  </>
  );
}



function getLinkIcon(link) {
  link = link.replace("https://", "").replace("http://", "")
  if (link.startsWith("nanswap.com/art/")) {
    return <img src='https://nanswap.com/faviconArt.png ' alt='logo-nanswap-art' style={{ width: '24px' }} />
  }
  else if (link.startsWith("nanswap.com/")) {
    return <img src='https://nanswap.com/favicon.png' alt='logo-nanswap' style={{ width: '24px' }} />
  }
  else if (link.startsWith("twitter.com/") || link.startsWith("x.com/")) {
      return <XOutlined />
  } else if (link.startsWith("instagram.com/")) {
      return <InstagramOutlined />
  } else if (link.startsWith('discord.gg/')) {
      return <DiscordOutlined />
  }
  else {
      return <GlobalOutlined />
  }
}
const ButtonLink = ({ url, title }) => {
  return <Button
    shape='round'
    size='large'
    style={{ display: 'block', width: '100%'}}
    href={url}
    target='_blank'
    rel='noopener noreferrer nofollow'
    key={url}>
    <div style={{ textAlign: "center" }}>
      <div style={{ position: "absolute" }}>
        {getLinkIcon(url)}
      </div>
      {title}
      {/* {url
        .replace(/(^\w+:|^)\/\//, '') // remove protocol
      } */}
    </div>
  </Button>
}
const UsernameAlias = ({defaultUsername}) => {
  let {
    username
  } = useParams();

  if (!username) {
    username = defaultUsername;
  }

  let url = process.env.REACT_APP_WELL_KNOWN_API + '/.well-known/full.json?names=' + username.toLowerCase();
  if (url.startsWith('https://xno.link/')) {
    url = url.replace('https://xno.link/', 'https://alias.xno.link/'); // fetching from subdomain
  }

  const { data, isLoading } = useSWR(url, fetcher);
  if (data && data?.username) {
    username = data.username;
  }
  const AliasTitle = () => {
  
    return <>
    {/* 
    maybe not usefull, to emphasize the alias
    <Typography.Title
    ellipsis
    style={{ textAlign: 'center', marginBottom: 0, marginTop: 4 }}
    level={1}
    >display name
    </Typography.Title> */}
    <Typography.Title
    onClick={() => {
      let r = copy("@" + username + "@xno.link");
      if (r) {
        message.success("Alias copied");
      } else {
        message.error("Failed to copy");
      }
    }}
    style={{ textAlign: 'center', cursor: 'pointer', marginTop: 4 }}
    level={username.length > 8 ? 5 : 3}
    >@{username}@xno.link
    </Typography.Title>
    </>
  }
  
  if (isLoading) return <Row style={{ "display": "flex", "alignItems": "center", "justifyContent": "center", "height": "50vh" }}>
  <Col lg={10} md={12} sm={24} xs={24} style={{ textAlign: "center" }}>
  <Card style={{ maxWidth: '420px', margin: '0 auto' }}>
      <AliasTitle />
    <Skeleton active />
  </Card>
  </Col>
  </Row>


  if (!data || data.error) return <div>
      <AliasTitle />
    <Result
    status={404}
    title="Alias not found"
    />
  <SmallCardClaim  />
  </div>

  const addressesFormatted = data['names'][0]['addresses'].map((address, i) => {
    return {
      content: <ButtonAddress
      username={username}
      key={data['names'][0]['addresses'][i]['_id']}
      address={data['names'][0]['addresses'][i]['address']}
      ticker={data['names'][0]['addresses'][i]['ticker']} />,
      id: data['names'][0]['addresses'][i]['_id']
    }
  })
  const linksFormatted = data['names'][0]['links'].map((link, i) => {
    return {
      content: <ButtonLink 
      title={link['title']}
      url={link['url']} key={link['_id']} type={link['type']} />,
      id: link['_id']
    }
  })
  const combinedLinksFormatted = [...addressesFormatted, ...linksFormatted]
  const sortedLinks = combinedLinksFormatted.sort((a, b) => {
    return data['names'][0]['positionIds'].indexOf(a.id) - data['names'][0]['positionIds'].indexOf(b.id)
  })

  return (<>
  <Helmet>
    <title>{username} | XNO.LINK</title>
    <meta name="description" content={`XNO.LINK page for ${username}.`} />
  </Helmet>
    <Row style={{ "display": "flex", "alignItems": "center", "justifyContent": "center", "height": "50vh" }}>
      <Col lg={10} md={12} sm={24} xs={24} style={{ textAlign: "center" }}>
        <Card style={{ maxWidth: '420px', margin: '0 auto' }}>
          {
            data['names'][0]['profile_picture'] &&
          <Avatar
          src={data['names'][0]['profile_picture']}
          size={64} />
        }
        <AliasTitle />
          { sortedLinks.length > 0 && <Divider /> }
          {
            data['names'][0] &&
            <>
              <Flex vertical gap={8}>
              {sortedLinks.map((link, i) => {
                return link.content
              })}
              </Flex>
            </>
          }

        </Card>
        <SmallCardClaim style={{ marginTop: 128 }} />
      </Col>
    </Row>

  </>
  );

  function SmallCardClaim({style = {}}) {
    return <Link to={`/`}>
      <Card style={{ maxWidth: '420px', margin: '0 auto', marginBottom: 64, ...style }} size='small'>
        <Typography.Text
          level={1} style={{ display: 'flex', alignItems: 'center', maxWidth: '580px', margin: '0 auto', justifyContent: 'center' }}>
          <img src="./xno-link-2.svg" alt='logo-nano' style={{ width: '22px', marginRight: 8 }} />
          Get your own free xno.link
        </Typography.Text>
      </Card>
    </Link>;
  }
};

export default UsernameAlias;