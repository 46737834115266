import React, { useState } from 'react';
import { Form, Input, Button, message, Typography, Space, Card, Collapse, Divider, Col, Row, ConfigProvider, theme, Menu, Tabs } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import * as webauthn from '@passwordless-id/webauthn';
import UsernameAlias from './UsernameAlias';
import { Header } from 'antd/es/layout/layout';
import useSWR from 'swr';
import { converters } from '../converters';

const Home = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [form] = Form.useForm();
  // const { data: usernameError } = useSWR(`${process.env.REACT_APP_API}/alias/check-username/?u=${username}`, (url) => fetch(url).then(r => r.json()));

  const itemsDemo = [
    {
      key: '1',
      label: 'Nanswap',
      image: './images/alias-nanswap.jpg',
    },
    {
      key: '2',
      label: 'Nanexplorer',
      video: './videos/alias-nanexplorer.mp4',
    },
    {
      key: '3',
      label: 'Nault',
      video: './videos/alias-nault.mp4',
    },
    {
      key: '4',
      label: 'Nautilus',
      video: './videos/alias-nautilus.MP4',
    },
  ];
  function claim(username, nanoAddress) {
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}/account?tab=alias${username ? `&username=${username}` : ''}${nanoAddress ? `&nanoAddress=${nanoAddress}` : ''}&src=xnolink`
  }
  return (<>

  <Row gutter={[16, 16]} align={"middle"} justify={"center"} >

    <Col xs={24} sm={24} md={7} lg={7}>
      <Typography.Title level={1} style={{marginBottom: 16, marginTop: 16}}>Free Nano Alias</Typography.Title>
      <Typography.Title level={5}>
        Create an easy to remember alias for your addresses.
      </Typography.Title>
        <Form 
        onFinish={(values) => {
          console.log(values);
          // window.location.href = `${process.env.REACT_APP_LOGIN_URL}/account?tab=alias${values.username ? `&username=${values.username}` : ''}${values.nanoAddress ? `&nanoAddress=${values.nanoAddress}` : ''}&src=xnolink`
          claim(values.username, values.nanoAddress)
        }}
        form={form}
        name="register" >
      <Form.Item
        name="username"
        // extra="Your username cannot be changed, so choose wisely!"
      >
        <Input 
        onChange={(e) => setUsername(e.target.value)}
        defaultValue={new URLSearchParams(window.location.search).get('username')}
        size='large'
        style={{maxWidth: 300, marginTop: 6}}
        // suffix=".xno.link"
        prefix={"xno.link /"}
        // prefix={<UserOutlined />}
        
        placeholder="Your Username" />
      </Form.Item>
      <Form.Item
      // for optimal UX, this field could be removed but then should have an onboarding page to easily add nano address when first login/register
          name="nanoAddress"
          extra="Optional - Can be added later."
          rules={[{ required: false, message: 'Please input a valid Nano address starting with nano_' }]}
        >
          <Input
            style={{ borderRadius: 8 }}
            size='large'
            prefix={
              <img src="https://nanswap.com/logo/XNO.svg" alt='logo-nano' style={{ width: '20px' }} />
            } placeholder="Your Nano Address" />

        </Form.Item>
      <Form.Item>

        <Button
        size='large'
        shape='round'
         type="primary" htmlType="submit">
          Claim your xno.link
        </Button>

      </Form.Item>
    </Form>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12}>
      <div 
      style={{
        display: 'flex', justifyContent: 'center', alignItems: 'stretch', height: '100%', flexDirection: 'column'}}>
        <img id="demo" src='./images/xnolink-screen.png' alt='demo' style={{
          borderRadius: 8,
        }} />
        {/* <UsernameAlias defaultUsername={"ben"} /> */}
      </div>
      </Col>
      </Row>
      <Row gutter={[16, 16]} align={"middle"} justify={"center"} >
      <Col xs={24} sm={24} md={19} lg={19}>
      <Typography.Title level={4} style={{marginTop: 150}}>
        Compatible with Nault, Nanswap, Nautilus and more
        </Typography.Title>
      <Typography.Paragraph type='secondary'>
      Use @username@xno.link as your nano address. It will automatically resolve to your nano address on compatible services. 
      </Typography.Paragraph>
      <Tabs 
      defaultActiveKey="1" tabPosition={
        window.innerWidth > 768 ? 'left' : 'top'
      } centered items={
        itemsDemo.map((item) => {
          return {
            key: item.key,
            label: item.label,
            forceRender: true,
            children: item.video ? <div
            key={item.key}
             style={{ margin: 'auto', width: "100%", display: 'flex', justifyContent: 'center' }}>
            <video
            playsInline
            muted
            style={{ borderRadius: 8 }}
            loop
            autoPlay
             width="250px">
              <source src={item.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            </div> : 
            <div 
            key={item.key}
            style={{ margin: 'auto', width: "100%", display: 'flex', justifyContent: 'center' }}>
            <img src={item.image} style={{ width: 250 }} /></div>,
          }
        })
      } />
      <Typography.Title level={4} style={{marginTop: 32}}>
        Receive donations with ease
        </Typography.Title>
      <Typography.Paragraph type='secondary'>
          We provide you a convenient page at xno.link/username to receive donations directly to your addresses, with always 0 fees.
      </Typography.Paragraph>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <video
      playsInline
      muted
      key={'video-donation'}
      loop
      autoPlay
      style={{ width: '250px', borderRadius: 8, marginTop: 8 }}>
        <source src='./videos/page-donation.mp4' type="video/mp4" />
        Your browser does not support the video tag.
      </video></div>
      <Typography.Title level={4} style={{marginTop: 32}}>
        Open protocol
      </Typography.Title>
      <Typography.Paragraph type='secondary'>
      Nano Alias is based on <a target='_blank' 
          href="https://github.com/mistakia/nano-community/blob/cae1dd3938fa1ca3e51c8d672187294bf3bcc8da/docs/getting-started-devs/integrations.md#nano-internet-identifiers">
            Nano Internet Identifiers 
            </a>. This open protocol allows anyone to self-host their Nano Alias using their own server and domain. 
            <br/>
            Nanswap provides this service for free trough the <a>xno.link</a> domain. <br/>
            Any service can resolve alias using <code>
              https://xno.link/.well-known/nano-currency.json?names=username
              </code>
      </Typography.Paragraph>
      <Typography.Title level={4} style={{marginTop: 32}}>
        Multiple currencies supported
      </Typography.Title>
      <Typography.Paragraph type='secondary'>
        <div style={{ display: 'flex', marginTop: 16 , justifyContent: 'space-evenly' }}>
        {
          Object.keys(converters).map((ticker) => {
            return <div
            key={ticker}
             style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <img src={converters[ticker].logo} style={{ width: 48, height: 48, cursor: 'pointer' }} />
              </div>
          })
        }
        </div>
      </Typography.Paragraph>
      </Col>
      
      </Row>
      <Collapse 
      ghost
      size='large'
      defaultActiveKey={[]} style={{ maxWidth: 500, margin: 'auto', marginTop: 64, display: "none" }}>
        <Collapse.Panel header="How to use Nano Alias?" key="1">
        <div>Use <code>@username.xno.link</code> as your nano address. <br/>
        On compatible services, it will automatically resolve to your nano address.
        </div>
        </Collapse.Panel>
        <Collapse.Panel header="How to receive donations?" key="2">
        We provide you a public page at <code>https://xno.link/username</code> that you can share to easily receive donations.
        </Collapse.Panel>
        <Collapse.Panel header="How Nano Alias Works?" key="3">
        Nano Alias is based on <a target='_blank' 
          href="https://github.com/mistakia/nano-community/blob/cae1dd3938fa1ca3e51c8d672187294bf3bcc8da/docs/getting-started-devs/integrations.md#nano-internet-identifiers">
            Nano Internet Identifiers 
            </a>. <br/>
            This open protocol allows anyone to self-host their Nano Alias using their own server and domain. 
            <br/>
            Nanswap provides this service for free through the <a>xno.link</a> domain.
        </Collapse.Panel>
      </Collapse>
      <Divider />
      <Button
      style={{margin: 'auto', display: 'block', marginTop: 16}}
      size='large'
      shape='round'
      type='primary'
      onClick={() => claim()}
      >
        Claim your xno.link
      </Button>
      <Divider />
      <a href="https://nanswap.com/?r=xnolink" target="_blank">
      <Card 
      style={{
        backgroundColor: 'transparent',
        maxWidth: '500px', margin: '0 auto', marginTop: 16, display: "flex", justifyContent: "center", textAlign: "center"}} bordered={false}>
        <div>
          Powered by
        </div>
        <div>
          <img src="https://nanswap.com/nanswap.svg" alt='logo-nano' style={{width: '164px'}} />
        </div>
      </Card>
      </a>
    </>
  );
};

export default Home;