import React from 'react';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import { Button, Card, Col, ConfigProvider, Layout, Menu, theme, Typography } from 'antd';
import Home from './components/Home';
import UsernameAlias from './components/UsernameAlias';

const { Content, Header } = Layout;

function App() {
  const NavBar = () => {
    return <Card
      size='small'
      style={{
        marginBottom: 16, borderRadius: 0,
      }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <Typography.Title
            level={3} style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', color: '#000034', margin: '0 auto', marginBottom: 8 }} >
            <img src="./xno-link-2.svg" alt='logo-nano' style={{ width: '30px', marginRight: 8 }} />
            XNO.LINK
          </Typography.Title>
        </div>
        <div>
          <Button
          href={`${process.env.REACT_APP_LOGIN_URL}/account?tab=alias&src=xnolink`} // src important or issues with already exist ref uses for invite code
            size='large'
            type='default'
          >
            Login
          </Button>
        </div>
      </div>
    </Card>
  }
  return (
    <ConfigProvider

    >
      <Layout className="layout" style={{ minHeight: '100vh' }}>


        <Router>
          <Routes>
            <Route exact path="/" element={
              <>
                <NavBar />
                <Content style={{ padding: '25px' }}>
                  <Home />
                </Content>
              </>} />
            <Route path="/:username" element={
              <>
                <Content style={{ padding: '25px' }}>
                  <UsernameAlias />
                </Content>
              </>}
              />
            {/* <Route exact path="/" element={<Login/>} /> */}
          </Routes>
        </Router>
        {/* <Layout.Footer style={{ textAlign: 'center' }}>XNO.LINK ©{new Date().getFullYear()}</Layout.Footer> */}
      </Layout>
    </ConfigProvider>
  );
}

export default App;